import React from 'react';
import './App.css';
import { Authenticator } from '@aws-amplify/ui-react';
import Console from './Console';
import '@aws-amplify/ui-react/styles.css';
import Koenigrufen from "./Koenigrufen";

type SquareProps = {
    value: string,
    onClick: Function
}

type SquareState = {
    value: string
}

function Square(props: SquareProps) {
    return (
        <button className="square" onClick={() => { props.onClick() }}>
            {props.value}
        </button>
    );
}

type BoardProps = {
    squares: string[],
    xIsNext: boolean,
    onClick: Function,
}


class Board extends React.Component<BoardProps, { }> {
    renderSquare(i: number) {
        return <Square value={this.props.squares[i]} onClick={() => { this.props.onClick(i)}}/>;
    }

    render() {
        return (
            <div>
                <div className="board-row">
                    {this.renderSquare(0)}
                    {this.renderSquare(1)}
                    {this.renderSquare(2)}
                </div>
                <div className="board-row">
                    {this.renderSquare(3)}
                    {this.renderSquare(4)}
                    {this.renderSquare(5)}
                </div>
                <div className="board-row">
                    {this.renderSquare(6)}
                    {this.renderSquare(7)}
                    {this.renderSquare(8)}
                </div>
            </div>
        );
    }
}

type GameState = {
    history: {squares: string[]}[],
    xIsNext: boolean,
    stepNumber: number,
}

class Game extends React.Component<{}, GameState> {

    constructor(props: any) {
        super(props);
        this.state = {
            history: [{
                squares: Array(9).fill(''),
            }],
            xIsNext: true,
            stepNumber: 0
        };
    }

    handleClick(i: number) {
        const history = this.state.history.slice(0, this.state.stepNumber + 1);
        const current = history[history.length - 1];
        const squares = current.squares.slice();
        if (calculateWinner(squares) || squares[i]) {
            return;
        }
        squares[i] = this.state.xIsNext ? 'X' : 'O';
        this.setState({
            history: history.concat([{
                squares: squares,
            }]),
            stepNumber: history.length,
            xIsNext: !this.state.xIsNext,
        });
    }

    jumpTo(step: number) {
        this.setState({
            stepNumber: step,
            xIsNext: (step % 2) === 0,
        });
    }

    render() {
        const history = this.state.history;
        const current = history[this.state.stepNumber];
        const winner = calculateWinner(current.squares);

        const moves = history.map((step, move) => {
            const desc = move ?
                'Go to move #' + move :
                'Go to game start';
            return (
                <li key={move}>
                    <button onClick={() => this.jumpTo(move)}>{desc}</button>
                </li>
            );
        });

        let status;
        if (winner) {
            status = 'Winner: ' + winner;
        } else {
            status = 'Next player: ' + (this.state.xIsNext ? 'X' : 'O');
        }


        return (
            <div className="game">
                <div className="game-board">
                    <Board squares={current.squares} xIsNext={this.state.xIsNext} onClick={(i: number) => this.handleClick(i)}/>
                </div>
                <div className="game-info">
                    <div>{status}</div>
                    <ol>{moves}</ol>
                </div>
            </div>
        );
    }
}

function calculateWinner(squares: string[]) {
    const lines = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6],
    ];
    for (let i = 0; i < lines.length; i++) {
        const [a, b, c] = lines[i];
        if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
            return squares[a];
        }
    }
    return null;
}

function App() {
    const gameState = {
        "currentState": "SCORES",
        "tick": 6066,
        "gameName": "XPJ",
        "rules": {
            "name": "SKOTY",
            "availableBonuses": [
                {
                    "bonusType": "GAME",
                    "scoreUnannounced": 0,
                    "displayCol": 1,
                    "displayRow": 0
                },
                {
                    "bonusType": "KING_ULTIMO",
                    "scoreUnannounced": 1,
                    "displayCol": 1,
                    "displayRow": 1
                },
                {
                    "bonusType": "PAGAT",
                    "scoreUnannounced": 1,
                    "displayCol": 2,
                    "displayRow": 0
                },
                {
                    "bonusType": "UHU",
                    "scoreUnannounced": 2,
                    "displayCol": 2,
                    "displayRow": 1
                },
                {
                    "bonusType": "KAKADU",
                    "scoreUnannounced": 3,
                    "displayCol": 3,
                    "displayRow": 0
                },
                {
                    "bonusType": "QUAPIL",
                    "scoreUnannounced": 4,
                    "displayCol": 3,
                    "displayRow": 1
                },
                {
                    "bonusType": "GEIER",
                    "scoreUnannounced": 5,
                    "displayCol": 4,
                    "displayRow": 0
                },
                {
                    "bonusType": "TRULL",
                    "scoreUnannounced": 1,
                    "displayCol": 5,
                    "displayRow": 0
                },
                {
                    "bonusType": "FOUR_KINGS",
                    "scoreUnannounced": 1,
                    "displayCol": 5,
                    "displayRow": 1
                },
                {
                    "bonusType": "SACK_PLUS_10",
                    "scoreUnannounced": 1,
                    "displayCol": 6,
                    "displayRow": 0
                },
                {
                    "bonusType": "SACK_PLUS_20",
                    "scoreUnannounced": 1,
                    "displayCol": 6,
                    "displayRow": 1
                },
                {
                    "bonusType": "VALAT",
                    "scoreUnannounced": 10,
                    "displayCol": 7,
                    "displayRow": 0
                }
            ],
            "availableBids": [
                {
                    "bid": {
                        "name": "MEIN_SPIEL"
                    },
                    "gameValue": -1,
                    "order": 0,
                    "goesOffDouble": false,
                    "displayRow": 0,
                    "displayCol": 0
                },
                {
                    "bid": {
                        "name": "PASS"
                    },
                    "gameValue": -1,
                    "order": 100,
                    "goesOffDouble": false,
                    "displayRow": 0,
                    "displayCol": 0
                },
                {
                    "bid": {
                        "name": "RUFER",
                        "cardRules": "POSITIVE"
                    },
                    "gameValue": 1,
                    "order": 1,
                    "goesOffDouble": false,
                    "displayRow": 1,
                    "displayCol": 1
                },
                {
                    "bid": {
                        "name": "TRISCHAKEN",
                        "cardRules": "CHOICE_POS_NEG"
                    },
                    "gameValue": 1,
                    "order": 1,
                    "goesOffDouble": false,
                    "displayRow": 2,
                    "displayCol": 1
                },
                {
                    "bid": {
                        "name": "PICCOLO",
                        "cardRules": "NEGATIVE"
                    },
                    "gameValue": 2,
                    "order": 2,
                    "goesOffDouble": false,
                    "displayRow": 3,
                    "displayCol": 1
                },
                {
                    "bid": {
                        "name": "SOLORUFER",
                        "cardRules": "POSITIVE"
                    },
                    "gameValue": 2,
                    "order": 3,
                    "goesOffDouble": false,
                    "displayRow": 0,
                    "displayCol": 2
                },
                {
                    "bid": {
                        "name": "PAGATRUFER",
                        "cardRules": "POSITIVE"
                    },
                    "gameValue": 1,
                    "order": 4,
                    "goesOffDouble": false,
                    "displayRow": 1,
                    "displayCol": 2
                },
                {
                    "bid": {
                        "name": "UHURUFER",
                        "cardRules": "POSITIVE"
                    },
                    "gameValue": 1,
                    "order": 5,
                    "goesOffDouble": false,
                    "displayRow": 2,
                    "displayCol": 2
                },
                {
                    "bid": {
                        "name": "BETTEL",
                        "cardRules": "NEGATIVE"
                    },
                    "gameValue": 4,
                    "order": 6,
                    "goesOffDouble": false,
                    "displayRow": 3,
                    "displayCol": 2
                },
                {
                    "bid": {
                        "name": "SECHSERDREIER",
                        "cardRules": "POSITIVE"
                    },
                    "gameValue": 4,
                    "order": 7,
                    "goesOffDouble": true,
                    "displayRow": 0,
                    "displayCol": 1
                },
                {
                    "bid": {
                        "name": "FARBENDREIER",
                        "cardRules": "FARBEN"
                    },
                    "gameValue": 5,
                    "order": 8,
                    "goesOffDouble": false,
                    "displayRow": 0,
                    "displayCol": 3
                },
                {
                    "bid": {
                        "name": "DREIER",
                        "cardRules": "POSITIVE"
                    },
                    "gameValue": 5,
                    "order": 9,
                    "goesOffDouble": false,
                    "displayRow": 1,
                    "displayCol": 3
                },
                {
                    "bid": {
                        "name": "KAKADURUFER",
                        "cardRules": "POSITIVE"
                    },
                    "gameValue": 1,
                    "order": 10,
                    "goesOffDouble": false,
                    "displayRow": 2,
                    "displayCol": 3
                },
                {
                    "bid": {
                        "name": "PICCOLO_SEMI_OUVERT",
                        "cardRules": "NEGATIVE"
                    },
                    "gameValue": 6,
                    "order": 11,
                    "goesOffDouble": false,
                    "displayRow": 3,
                    "displayCol": 3
                },
                {
                    "bid": {
                        "name": "BETTEL_SEMI_OUVERT",
                        "cardRules": "NEGATIVE"
                    },
                    "gameValue": 6,
                    "order": 12,
                    "goesOffDouble": false,
                    "displayRow": 0,
                    "displayCol": 4
                },
                {
                    "bid": {
                        "name": "FARBENEINER",
                        "cardRules": "FARBEN"
                    },
                    "gameValue": 7,
                    "order": 13,
                    "goesOffDouble": false,
                    "displayRow": 1,
                    "displayCol": 4
                },
                {
                    "bid": {
                        "name": "EINER",
                        "cardRules": "POSITIVE"
                    },
                    "gameValue": 7,
                    "order": 14,
                    "goesOffDouble": false,
                    "displayRow": 2,
                    "displayCol": 4
                },
                {
                    "bid": {
                        "name": "QUAPILRUFER",
                        "cardRules": "POSITIVE"
                    },
                    "gameValue": 1,
                    "order": 15,
                    "goesOffDouble": false,
                    "displayRow": 3,
                    "displayCol": 4
                },
                {
                    "bid": {
                        "name": "GEIERRUFER",
                        "cardRules": "POSITIVE"
                    },
                    "gameValue": 1,
                    "order": 16,
                    "goesOffDouble": false,
                    "displayRow": 0,
                    "displayCol": 5
                },
                {
                    "bid": {
                        "name": "TRICCOLO_OUVERT",
                        "cardRules": "NEGATIVE"
                    },
                    "gameValue": 8,
                    "order": 17,
                    "goesOffDouble": false,
                    "displayRow": 1,
                    "displayCol": 5
                },
                {
                    "bid": {
                        "name": "ZWICCOLO_OUVERT",
                        "cardRules": "NEGATIVE"
                    },
                    "gameValue": 8,
                    "order": 18,
                    "goesOffDouble": false,
                    "displayRow": 2,
                    "displayCol": 5
                },
                {
                    "bid": {
                        "name": "PICCOLO_OUVERT",
                        "cardRules": "NEGATIVE"
                    },
                    "gameValue": 8,
                    "order": 19,
                    "goesOffDouble": false,
                    "displayRow": 3,
                    "displayCol": 5
                },
                {
                    "bid": {
                        "name": "BETTEL_OUVERT",
                        "cardRules": "NEGATIVE"
                    },
                    "gameValue": 8,
                    "order": 20,
                    "goesOffDouble": false,
                    "displayRow": 0,
                    "displayCol": 6
                },
                {
                    "bid": {
                        "name": "FARBENSOLO",
                        "cardRules": "FARBEN"
                    },
                    "gameValue": 10,
                    "order": 21,
                    "goesOffDouble": false,
                    "displayRow": 1,
                    "displayCol": 6
                },
                {
                    "bid": {
                        "name": "SOLODREIER",
                        "cardRules": "POSITIVE"
                    },
                    "gameValue": 10,
                    "order": 22,
                    "goesOffDouble": false,
                    "displayRow": 2,
                    "displayCol": 6
                }
            ]
        },
        "locked": true,
        "maxPlayers": 6,
        "maxHands": 0,
        "publicPlayerStates": [
            {
                "name": "Bali R",
                "previousScore": 49,
                "scoreForThisHand": 14,
                "score": 63,
                "lastBid": "PASS",
                "lastAnnouncement": {},
                "log": [],
                "hand": [
                    {
                        "suit": "CLUBS",
                        "rank": "KNIGHT",
                        "value": 3,
                        "order": 3
                    },
                    {
                        "suit": "CLUBS",
                        "rank": "SEVEN",
                        "value": 1,
                        "order": 8
                    },
                    {
                        "suit": "CLUBS",
                        "rank": "NINE",
                        "value": 1,
                        "order": 6
                    },
                    {
                        "suit": "CLUBS",
                        "rank": "EIGHT",
                        "value": 1,
                        "order": 7
                    },
                    {
                        "suit": "TAROCKS",
                        "rank": "THIRTEEN",
                        "value": 1,
                        "order": 10
                    },
                    {
                        "suit": "SPADES",
                        "rank": "JACK",
                        "value": 2,
                        "order": 4
                    },
                    {
                        "suit": "TAROCKS",
                        "rank": "TEN",
                        "value": 1,
                        "order": 13
                    },
                    {
                        "suit": "TAROCKS",
                        "rank": "TWO",
                        "value": 1,
                        "order": 21
                    }
                ],
                "declarersSide": false,
                "tricksTaken": 2,
                "cardPlayedToThisTrick": {
                    "suit": "DIAMONDS",
                    "rank": "TWO",
                    "value": 1,
                    "order": 6
                },
                "orderPlayedToThisTrick": 4,
                "wonThisTrick": false,
                "inThisHand": true,
                "readyForNext": false
            },
            {
                "score": 0,
                "log": [],
                "hand": [],
                "tricksTaken": 0,
                "wonThisTrick": false,
                "inThisHand": false,
                "readyForNext": false
            },
            {
                "score": 0,
                "lastBid": "EINER",
                "lastAnnouncement": {},
                "log": [],
                "hand": [
                    {
                        "suit": "HEARTS",
                        "rank": "KING",
                        "value": 5,
                        "order": 1
                    },
                    {
                        "suit": "TAROCKS",
                        "rank": "SKUS",
                        "value": 5,
                        "order": 1
                    },
                    {
                        "suit": "HEARTS",
                        "rank": "FOUR",
                        "value": 1,
                        "order": 8
                    },
                    {
                        "suit": "HEARTS",
                        "rank": "THREE",
                        "value": 1,
                        "order": 7
                    },
                    {
                        "suit": "TAROCKS",
                        "rank": "FIFTEEN",
                        "value": 1,
                        "order": 8
                    },
                    {
                        "suit": "TAROCKS",
                        "rank": "SEVENTEEN",
                        "value": 1,
                        "order": 6
                    },
                    {
                        "suit": "HEARTS",
                        "rank": "KNIGHT",
                        "value": 3,
                        "order": 3
                    },
                    {
                        "suit": "HEARTS",
                        "rank": "QUEEN",
                        "value": 4,
                        "order": 2
                    },
                    {
                        "suit": "CLUBS",
                        "rank": "KING",
                        "value": 5,
                        "order": 1
                    },
                    {
                        "suit": "TAROCKS",
                        "rank": "FIVE",
                        "value": 1,
                        "order": 18
                    },
                    {
                        "suit": "CLUBS",
                        "rank": "JACK",
                        "value": 2,
                        "order": 4
                    },
                    {
                        "suit": "CLUBS",
                        "rank": "TEN",
                        "value": 1,
                        "order": 5
                    },
                    {
                        "suit": "DIAMONDS",
                        "rank": "QUEEN",
                        "value": 4,
                        "order": 2
                    },
                    {
                        "suit": "TAROCKS",
                        "rank": "SEVEN",
                        "value": 1,
                        "order": 16
                    },
                    {
                        "suit": "DIAMONDS",
                        "rank": "FOUR",
                        "value": 1,
                        "order": 8
                    },
                    {
                        "suit": "DIAMONDS",
                        "rank": "ACE",
                        "value": 1,
                        "order": 5
                    },
                    {
                        "suit": "DIAMONDS",
                        "rank": "THREE",
                        "value": 1,
                        "order": 7
                    },
                    {
                        "suit": "TAROCKS",
                        "rank": "MOND",
                        "value": 5,
                        "order": 2
                    },
                    {
                        "suit": "DIAMONDS",
                        "rank": "JACK",
                        "value": 2,
                        "order": 4
                    },
                    {
                        "suit": "TAROCKS",
                        "rank": "TWENTY",
                        "value": 1,
                        "order": 3
                    }
                ],
                "tricksTaken": 5,
                "cardPlayedToThisTrick": {
                    "suit": "TAROCKS",
                    "rank": "EIGHTEEN",
                    "value": 1,
                    "order": 5
                },
                "orderPlayedToThisTrick": 1,
                "wonThisTrick": false,
                "inThisHand": true,
                "readyForNext": false
            },
            {
                "name": "Johann M",
                "previousScore": -200,
                "scoreForThisHand": 14,
                "score": -186,
                "lastBid": "PASS",
                "lastAnnouncement": {},
                "log": [],
                "hand": [
                    {
                        "suit": "SPADES",
                        "rank": "SEVEN",
                        "value": 1,
                        "order": 8
                    },
                    {
                        "suit": "SPADES",
                        "rank": "QUEEN",
                        "value": 4,
                        "order": 2
                    },
                    {
                        "suit": "TAROCKS",
                        "rank": "PAGAT",
                        "value": 5,
                        "order": 22
                    },
                    {
                        "suit": "SPADES",
                        "rank": "KNIGHT",
                        "value": 3,
                        "order": 3
                    },
                    {
                        "suit": "SPADES",
                        "rank": "NINE",
                        "value": 1,
                        "order": 6
                    },
                    {
                        "suit": "SPADES",
                        "rank": "TEN",
                        "value": 1,
                        "order": 5
                    },
                    {
                        "suit": "TAROCKS",
                        "rank": "NINE",
                        "value": 1,
                        "order": 14
                    },
                    {
                        "suit": "SPADES",
                        "rank": "EIGHT",
                        "value": 1,
                        "order": 7
                    },
                    {
                        "suit": "DIAMONDS",
                        "rank": "KING",
                        "value": 5,
                        "order": 1
                    },
                    {
                        "suit": "TAROCKS",
                        "rank": "TWELVE",
                        "value": 1,
                        "order": 11
                    },
                    {
                        "suit": "TAROCKS",
                        "rank": "SIXTEEN",
                        "value": 1,
                        "order": 7
                    },
                    {
                        "suit": "TAROCKS",
                        "rank": "EIGHT",
                        "value": 1,
                        "order": 15
                    },
                    {
                        "suit": "DIAMONDS",
                        "rank": "TWO",
                        "value": 1,
                        "order": 6
                    },
                    {
                        "suit": "TAROCKS",
                        "rank": "EIGHTEEN",
                        "value": 1,
                        "order": 5
                    },
                    {
                        "suit": "TAROCKS",
                        "rank": "NINETEEN",
                        "value": 1,
                        "order": 4
                    },
                    {
                        "suit": "TAROCKS",
                        "rank": "FOURTEEN",
                        "value": 1,
                        "order": 9
                    }
                ],
                "declarersSide": false,
                "tricksTaken": 4,
                "cardPlayedToThisTrick": {
                    "suit": "TAROCKS",
                    "rank": "NINETEEN",
                    "value": 1,
                    "order": 4
                },
                "orderPlayedToThisTrick": 2,
                "wonThisTrick": false,
                "inThisHand": true,
                "readyForNext": false
            },
            {
                "score": 0,
                "log": [],
                "hand": [],
                "tricksTaken": 0,
                "wonThisTrick": false,
                "inThisHand": false,
                "readyForNext": false
            },
            {
                "name": "Daniel d",
                "previousScore": 14,
                "scoreForThisHand": 14,
                "score": 28,
                "lastBid": "PASS",
                "lastAnnouncement": {},
                "log": [],
                "hand": [
                    {
                        "suit": "TAROCKS",
                        "rank": "SIX",
                        "value": 1,
                        "order": 17
                    },
                    {
                        "suit": "TAROCKS",
                        "rank": "THREE",
                        "value": 1,
                        "order": 20
                    },
                    {
                        "suit": "TAROCKS",
                        "rank": "FOUR",
                        "value": 1,
                        "order": 19
                    },
                    {
                        "suit": "TAROCKS",
                        "rank": "ELEVEN",
                        "value": 1,
                        "order": 12
                    }
                ],
                "declarersSide": false,
                "tricksTaken": 1,
                "cardPlayedToThisTrick": {
                    "suit": "TAROCKS",
                    "rank": "FOURTEEN",
                    "value": 1,
                    "order": 9
                },
                "orderPlayedToThisTrick": 3,
                "wonThisTrick": false,
                "inThisHand": true,
                "readyForNext": false
            }
        ],
        "log": [
            {
                "playerIndex": -1,
                "message": [
                    "_MSG_HAND_NUMBER",
                    "96"
                ]
            },
            {
                "playerIndex": -1,
                "message": [
                    "_MSG_DECLARERS_TEAM_POINTS",
                    "33"
                ]
            },
            {
                "playerIndex": -1,
                "message": [
                    "_MSG_BONUS",
                    "_GAME",
                    "_KONTRA",
                    "-14"
                ]
            },
            {
                "playerIndex": -1,
                "message": [
                    "_MSG_TOTAL_HAND_SCORE",
                    "-14"
                ]
            }
        ],
        "talon": [
            {
                "suit": "HEARTS",
                "rank": "ACE",
                "value": 1,
                "order": 5
            },
            {
                "suit": "SPADES",
                "rank": "KING",
                "value": 5,
                "order": 1
            },
            {
                "suit": "HEARTS",
                "rank": "TWO",
                "value": 1,
                "order": 6
            },
            {
                "suit": "HEARTS",
                "rank": "JACK",
                "value": 2,
                "order": 4
            },
            {
                "suit": "DIAMONDS",
                "rank": "KNIGHT",
                "value": 3,
                "order": 3
            },
            {
                "suit": "CLUBS",
                "rank": "QUEEN",
                "value": 4,
                "order": 2
            }
        ],
        "handNumber": 96,
        "currentPlayerIndex": -1,
        "inactivePlayerNames": [
            "Roman S",
            "Michael G"
        ],
        "inactivePlayerScores": [
            65,
            30
        ],
        "vorhand": 3,
        "declarer": 2,
        "status": [
            "_STATUS_HAND_COMPLETE",
            "96"
        ],
        "currentContract": {
            "bid": {
                "name": "EINER",
                "cardRules": "POSITIVE"
            },
            "gameValue": 7,
            "order": 14,
            "goesOffDouble": false,
            "displayRow": 2,
            "displayCol": 4
        },
        "highestBid": {
            "bid": {
                "name": "EINER",
                "cardRules": "POSITIVE"
            },
            "gameValue": 7,
            "order": 14,
            "goesOffDouble": false,
            "displayRow": 2,
            "displayCol": 4
        },
        "leadType": "VORHAND",
        "cardRules": "POSITIVE",
        "talonUsage": "TAKE_1_EXPOSED",
        "displayTalon": true,
        "bonuses": [
            {
                "bonusType": "GAME",
                "doublingChain": [
                    2,
                    0
                ]
            }
        ],
        "activityStatus": "IN_PROGRESS",
        "lastActionTime": {
            "seconds": 1689975883,
            "nanos": 69597000
        }
    };


  return (
      <Authenticator signUpAttributes={['given_name', 'family_name']} socialProviders={['google']}>
        {({ signOut, user }) => (
            <div>
              <p>Welcome {user?.username || ''}</p>
              <button onClick={signOut}>Sign1 out</button>
              <Console />
                {/*<Game />*/}
                {/*<Koenigrufen {...gameState}/>*/}
            </div>
        )}
      </Authenticator>
  );
}

export default App;
