import React, {useState} from 'react';
import './Console.css';
import '@aws-amplify/ui-react/styles.css';

class Console extends React.Component<{}, { gameId: string, gameStep: string, latestMessage: string }> {
    ws = new WebSocket("wss://c83fpxrqkh.execute-api.eu-west-2.amazonaws.com/main/");
    constructor(props: any) {
        super(props);
        this.state = {
            "gameId": "123",
            "gameStep": "0",
            "latestMessage": ""
        };
    }

    componentDidMount() {
        this.ws.onopen = (event) => {
            this.ws.send(JSON.stringify({
                action: "testGame-action",
                gameId: this.state['gameId'],
                gameStep: "0",
                message: "hello"
            }));
        };

        this.ws.onmessage = (event) => {
            let data = JSON.parse(event.data);
            try {
                console.log(data);
                if (data.error) {
                    alert(data.error);
                }
                this.setState(data.game);
            } catch (err) {
                console.log(err);
            }
        };
    }

    render() {
        return <div>
        {JSON.stringify(this.state)}
        <button onClick={() => this.ws.send(JSON.stringify({
            action: "testGame-action",
            gameId: this.state.gameId,
            gameStep: this.state.gameStep,
            message: "Play",
            actionType: "PLAY"
        }))}>Play</button>
            <button onClick={() => this.ws.send(JSON.stringify({
                action: "testGame-action",
                gameId: this.state.gameId,
                gameStep: this.state.gameStep,
                message: "Pass",
                actionType: "PASS"
            }))}>Pass</button>
            <button onClick={() => this.ws.send(JSON.stringify({
                action: "testGame-action",
                gameId: this.state.gameId,
                gameStep: this.state.gameStep,
                message: "Flip Table",
                actionType: "FLIP_TABLE"
            }))}>Flip Table</button>
        </div>;
    }

};

export default Console;
